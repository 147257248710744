import { useEffect, useMemo, useState } from 'react';

import { Actions, AssetType } from '@pages/home-page/enums';
import {
  AssetToDelete,
  CollapsibleItemsState,
  DataItem,
  GroupedKnowledgeItems,
  SelectedAsset,
} from '@pages/home-page/types';

import AssetDataItems from './AssetDataItems';
import { CollapsibleAssetWrapper } from './CollapsibleAssetWrapper';
import AddEditModals from '../add-edit-modals/AddEditModals';
import DeleteAssetModal from '../delete-asset-modal/DeleteAssetModal';

type CollapsibleAssetItemProps = {
  assetItem: {
    id: AssetType;
    header: string;
    tooltipMessage: string;
    dataItems: DataItem[];
    imageClassName?: string;
  };
  collapsibleItemsState: CollapsibleItemsState;
  isLoading: boolean;
};

const CollapsibleAssetItem = ({
  assetItem,
  collapsibleItemsState,
  isLoading,
}: CollapsibleAssetItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(
    collapsibleItemsState.isCollapseAll,
  );

  const [filteredKnowledgeItems, setFilteredKnowledgeItems] =
    useState<GroupedKnowledgeItems | null>(null);

  const [assetToDelete, setAssetToDelete] = useState<AssetToDelete>({
    id: null,
    assetType: null,
  });
  const [selectedAction, setSelectedAction] = useState<SelectedAsset | null>(
    null,
  );

  useEffect(() => {
    setIsCollapsed(collapsibleItemsState.isCollapseAll);
  }, [collapsibleItemsState]);

  const handleDelete = (id: number, assetType: AssetType) => {
    setAssetToDelete({
      id: id,
      assetType: assetType,
    });
  };

  const handleEdit = (item: DataItem, assetType: AssetType) => {
    setSelectedAction({ action: Actions.EDIT, assetType, asset: item });
  };

  const onDeleteModalClose = () => {
    setAssetToDelete({
      id: null,
      assetType: null,
    });
  };

  const getGroupedKnowledgeItems = useMemo(() => {
    if (assetItem.id !== AssetType.KNOWLEDGE) return null;

    return (dataItems: DataItem[]) => {
      return dataItems.reduce(
        (knowledgeCategory: { [key: string]: DataItem[] }, item: DataItem) => {
          const { name } = item;
          if (!(name in knowledgeCategory)) {
            knowledgeCategory[name] = [];
          }
          knowledgeCategory[name].push(item);
          return knowledgeCategory;
        },
        {},
      );
    };
  }, [assetItem.id, assetItem.dataItems]);

  return (
    <>
      <CollapsibleAssetWrapper
        setFilteredKnowledgeItems={setFilteredKnowledgeItems}
        assetType={assetItem?.id}
        header={assetItem.header}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        setSelectedAction={setSelectedAction}
        {...(assetItem.id === AssetType.KNOWLEDGE && {
          knowledgeDataItems: getGroupedKnowledgeItems?.(assetItem.dataItems),
        })}
      />
      {!isCollapsed && (
        <AssetDataItems
          dataItems={assetItem?.dataItems}
          isLoading={isLoading}
          imageClassName={assetItem?.imageClassName || ''}
          assetType={assetItem?.header}
          handleDelete={(id, assetType) => handleDelete(id, assetType)}
          handleEdit={(item, assetType) => handleEdit(item, assetType)}
          {...(assetItem.id === AssetType.KNOWLEDGE && {
            knowledgeDataItems:
              filteredKnowledgeItems ??
              getGroupedKnowledgeItems?.(assetItem.dataItems),
          })}
          isFilteredKnowledgeDataItems={filteredKnowledgeItems ? true : false}
        />
      )}
      {assetToDelete.id && assetToDelete.assetType && (
        <DeleteAssetModal
          assetToDelete={assetToDelete}
          onCloseModal={onDeleteModalClose}
        />
      )}
      {selectedAction && (
        <AddEditModals
          selectedAsset={selectedAction}
          setSelectedAction={setSelectedAction}
          isEdit={selectedAction?.action === Actions.EDIT}
        />
      )}
    </>
  );
};

export default CollapsibleAssetItem;
