import { isNil } from 'lodash';

import { RGBAColorType } from '@pages/home-page/types';

const round = (
  number: number,
  digits = 0,
  base = Math.pow(10, digits),
): number => {
  return Math.round(base * number) / base;
};

export const formatHexColorValue = (value: number): string => {
  const hex = value.toString(16).toUpperCase();

  return hex.length === 1 ? '0' + hex : hex;
};

export const rgbaToHex = (rgba: number[] | undefined): string | null => {
  if (!rgba) return null;
  const [r, g, b, a] = rgba;
  const red = round(r).toString(16).padStart(2, '0');
  const green = round(g).toString(16).padStart(2, '0');
  const blue = round(b).toString(16).padStart(2, '0');
  const alpha =
    a === 255
      ? 'FF'
      : Math.round(a * 255)
          .toString(16)
          .padStart(2, '0');

  return `#${red}${green}${blue}${alpha}`.toUpperCase();
};

export const hexToRgba = (hex: string) => {
  if (hex[0] === '#') hex = hex.substring(1);

  let r, g, b, a;

  if (hex.length < 6) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
    a = hex.length === 4 ? round(parseInt(hex[3] + hex[3], 16) / 255, 2) : 1;
  } else {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
    a =
      hex.length === 8 ? round(parseInt(hex.substring(6, 8), 16) / 255, 2) : 1;
  }

  return [r, g, b, a];
};

export const getRgbaInputBorderRadius = (name: string) => {
  if (name === 'r')
    return 'rounded-tl rounded-bl rounded-tr-none rounded-br-none';
  if (name === 'a')
    return 'rounded-tr rounded-br rounded-tl-none rounded-bl-none';

  return 'rounded-none';
};

export const calculateRgbaFormat = (hex: string) => {
  if (hex) {
    const [r, g, b, a] = hexToRgba(hex);

    return {
      r,
      g,
      b,
      a: Math.round(a * 255),
    };
  }

  return undefined;
};

export const isRgbaInvalid = (color: RGBAColorType) => {
  if (
    isNil(color) ||
    Number(color.a) > 255 ||
    Number(color.a) < 0 ||
    Number(color.b) > 255 ||
    Number(color.b) < 0 ||
    Number(color.g) > 255 ||
    Number(color.g) < 0 ||
    Number(color.r) > 255 ||
    Number(color.r) < 0
  ) {
    return true;
  }

  return false;
};

export const hexToRgbaString = (hex: string, alpha: number = 1): string => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
