import { FC, useEffect, useState } from 'react';

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { AssetType } from '@pages/home-page/enums';
import { useUpdateAssetsHierarchy } from '@pages/home-page/hooks/use-update-assets-hierarchy';
import { DataItem, GroupedKnowledgeItems } from '@pages/home-page/types';

import DraggableAttributeSection from './DraggableAttributeSection';

interface KnowledgeAttributeItemsProps {
  knowledgeDataItems: GroupedKnowledgeItems;
  workspaceId: number;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  isFiltered?: boolean;
}

const KnowledgeAttributeItems: FC<KnowledgeAttributeItemsProps> = ({
  knowledgeDataItems,
  workspaceId,
  handleDelete,
  handleEdit,
  isFiltered = false,
}) => {
  const { mutateAsync: updateAssetHierarchy } = useUpdateAssetsHierarchy();

  const [allKnowledgeAttributeItems, setAllKnowledgeAttributeItems] =
    useState(knowledgeDataItems);

  // To update items on deletion and edit
  useEffect(() => {
    setAllKnowledgeAttributeItems(knowledgeDataItems);
  }, [knowledgeDataItems]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setAllKnowledgeAttributeItems(prevItems => {
        const attributeItems = { ...prevItems };

        const category = Object.keys(attributeItems).find(key =>
          attributeItems[key]?.some(item => item.id === active.id),
        );

        if (category && attributeItems[category]) {
          const draggedItemIndex = attributeItems[category].findIndex(
            item => item.id === active.id,
          );
          const targetItemIndex = attributeItems[category].findIndex(
            item => item.id === over?.id,
          );

          const draggedItem = attributeItems[category][draggedItemIndex];
          const targetItem = attributeItems[category][targetItemIndex];

          if (
            draggedItem?.parentClientId !== workspaceId ||
            targetItem?.parentClientId !== workspaceId
          ) {
            const allInheritedItems = attributeItems[category].filter(
              item => item.parentClientId !== workspaceId,
            );

            // Remove all items with different parentClientId from the current category, leaving only items with parentClientId equal to workspaceId
            attributeItems[category] = attributeItems[category].filter(
              item => item.parentClientId === workspaceId,
            );

            // Insert inherited items as a group at the target index
            attributeItems[category].splice(
              draggedItem?.parentClientId !== workspaceId
                ? targetItemIndex
                : draggedItemIndex,
              0,
              ...allInheritedItems,
            );
          } else {
            attributeItems[category] = arrayMove(
              attributeItems[category],
              draggedItemIndex,
              targetItemIndex,
            );
          }
        }

        const allAttributeIds = Object.values(attributeItems).flatMap(
          categoryItems => categoryItems?.map(item => item.id),
        );

        updateAssetHierarchy({
          clientId: workspaceId,
          payload: {
            attributesHierarchy: allAttributeIds as number[],
          },
        });

        return attributeItems;
      });
    }
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      {Object.entries(
        isFiltered ? knowledgeDataItems : allKnowledgeAttributeItems,
      ).map(([knowledgeCategory, items]) => {
        return (
          <DraggableAttributeSection
            workspaceId={workspaceId}
            knowledgeCategory={knowledgeCategory}
            attributeItems={items || []}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            isDraggable={!isFiltered}
          />
        );
      })}
      <DragOverlay>{null}</DragOverlay>
    </DndContext>
  );
};

export default KnowledgeAttributeItems;
