import { Ref, useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { AngleDown, AngleUp } from '@assets/icons';
import { Language } from '@pages/home-page/enums';

type LanguageOption = { id: Language; label: string };

type Props = {
  options: LanguageOption[];
  selectedLanguage: LanguageOption;
  onLanguageSelect: (languageOption: LanguageOption) => void;
};

export const ChatLanguageSelect = ({
  options,
  selectedLanguage,
  onLanguageSelect,
}: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [50, 15],
        },
      },
    ],
  });

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <PopoverButton
            ref={
              setReferenceElement as unknown as (
                instance: HTMLButtonElement | null,
              ) => void
            }
            className="w-[85px] flex justify-between items-center px-2 space-x-2 focus:outline-none"
          >
            <p className="truncate uppercase">{selectedLanguage.id}</p>
            {open ? (
              <AngleUp className="text-black" />
            ) : (
              <AngleDown className="text-black" />
            )}
          </PopoverButton>
          <PopoverPanel
            className="w-[180px] max-h-[370px] flex flex-col py-2 bg-white rounded-md shadow-2xl z-10"
            ref={setPopperElement as Ref<HTMLDivElement> | undefined}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="px-3 py-2 text-sm text-gray-3">Choose language</div>
            <div className="overflow-y-auto">
              {options.map(option => (
                <button
                  key={option.id}
                  className="w-full p-3 text-sm text-left hover:bg-lightest"
                  onClick={e => {
                    onLanguageSelect(option);
                    e.preventDefault();
                    close();
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
