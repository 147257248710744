import { ChangeEvent, FC, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Import } from '@assets/icons';
import { Button, Input } from '@components/index';
import { useImportAssetIds } from '@pages/home-page/hooks/use-import-asset-ids';
import { workspace } from '@store/atoms/workspace';

const ID_LIMIT = 25;

interface AssetIdInputProp {
  showImportButton?: boolean;
  classOverride?: string;
}

const AssetIdInput: FC<AssetIdInputProp> = ({
  showImportButton = false,
  classOverride = '',
}) => {
  const currentWorkspace = useRecoilValue(workspace);
  const [assetIds, setAssetIds] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { mutateAsync: importIds, isLoading } = useImportAssetIds();

  const handleImport = () => {
    if (assetIds.length) {
      const distinctIds = assetIds
        .replaceAll(' ', '')
        .split(',')
        .reduce((list: string[], current: string) => {
          if (!list.includes(current)) {
            list.push(current.toLowerCase().trim());
          }

          return list;
        }, []);

      if (distinctIds.length > ID_LIMIT) {
        setErrorMessage(`You've entered over ${ID_LIMIT} IDs`);
      } else {
        setErrorMessage('');
        importIds({
          assetIds: distinctIds,
          clientId: currentWorkspace.id,
          clientUuid: currentWorkspace.uuid,
        });
        setAssetIds('');
      }
    } else {
      setErrorMessage('Asset id is required');
    }
  };

  const handleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setAssetIds(e.target.value);
  };

  return (
    <div className="w-full">
      <div className="flex items-end justify-center w-full gap-4">
        <Input
          containerClass={`w-[80%] ${classOverride}`}
          value={assetIds}
          placeholder="Eg: 12345, 23456, etc..."
          onChange={handleOnchange}
          showErrorMessage={false}
          error={errorMessage}
          label="Upload from 'The Asset Bank'"
        />
        {!showImportButton && <Import onClick={handleImport} />}
      </div>
      {showImportButton && (
        <div className="mt-4">
          <Button
            label="Import"
            variant="contained"
            isLoading={isLoading}
            isDisabled={!!errorMessage || assetIds.length === 0}
            onClick={handleImport}
            classOverride="!text-sm"
          />
        </div>
      )}
    </div>
  );
};
export default AssetIdInput;
