import { Dispatch, FC, SetStateAction, useState } from 'react';

import { RgbaColorPicker } from 'react-colorful';

import { Input, Select, SelectTypeRefComponent } from '@components/index';
import { InputTypes } from '@enums/input';
import { HEX_CODE_REGEX } from '@lib/regex';
import {
  COLOR_SELECT_OPTIONS,
  PRESET_COLORS,
  RGBA_VALUE,
} from '@pages/home-page/constants';
import { ColorCodeCategory } from '@pages/home-page/enums';
import { RGBAColorType } from '@pages/home-page/types';
import { getRgbaInputBorderRadius, hexToRgba, rgbaToHex } from '@utils/color';

interface CustomColorPickerProps {
  color: RGBAColorType;
  setColor: Dispatch<SetStateAction<{ hex: string; rgba: RGBAColorType }>>;
  hexColor: string;
}

const CustomColorPicker: FC<CustomColorPickerProps> = ({
  color,
  setColor,
  hexColor,
}) => {
  const [colorCode, setColorCode] = useState(COLOR_SELECT_OPTIONS[0]);

  const checkIsColorCodeValid = (newColor: string): boolean =>
    HEX_CODE_REGEX.test(newColor);

  const handleColorChange = (newColor: string) => {
    setColor(prev => ({ ...prev, hex: newColor }));
    if (checkIsColorCodeValid(newColor)) {
      setColor(prev => {
        const [r, g, b, a] = hexToRgba(newColor);

        return {
          ...prev,
          rgba: {
            r,
            g,
            b,
            a: Math.round(a * 255),
          },
        };
      });
    }
  };

  const handleRgbaColorChange = (newColor: RGBAColorType) => {
    const updatedColor = { ...newColor, a: newColor.a * 255 };
    const newHexColor = rgbaToHex([
      newColor.r,
      newColor.g,
      newColor.b,
      newColor.a,
    ]) as string;
    setColor({ hex: newHexColor, rgba: updatedColor });
  };

  const handleRgbaInputChange = (key: string, value: string) => {
    const newColor = {
      ...color,
      [key]: value,
    };
    const alpha = newColor.a ? Number((newColor.a / 255).toFixed(2)) : 255;
    const newHexColor = rgbaToHex([
      newColor.r,
      newColor.g,
      newColor.b,
      alpha,
    ]) as string;
    setColor({ hex: newHexColor, rgba: newColor });
  };

  const onSelect = (data: { id: ColorCodeCategory; label: string }) => {
    setColorCode(data);
  };

  const hasInvalidRgbaValue = RGBA_VALUE.some(key => {
    const rgbaKey = key as keyof RGBAColorType;

    return Number(color[rgbaKey]) > 255 || Number(color[rgbaKey]) < 0;
  });

  return (
    <div className="flex">
      <section className="custom-layout custom-pointers m-4 ml-7">
        <div className="text-sm font-medium mb-2">Custom colour</div>
        <RgbaColorPicker
          color={{ ...color, a: Number(color.a) > 255 ? 1 : color.a / 255 }}
          onChange={handleRgbaColorChange}
        />
        <div className="flex items-center justify-between mt-4">
          <Select
            options={COLOR_SELECT_OPTIONS}
            selectedOption={colorCode}
            placeHolder="Select category"
            onOptionSelect={onSelect}
            PopperRefComponent={SelectTypeRefComponent}
            classOverride="w-[70px]"
          />

          {colorCode.id === ColorCodeCategory.HEX ? (
            <Input
              value={hexColor}
              customInputClass="w-[132px] ml-6 outline-none border-[1px] px-3 rounded border-gray-4"
              onChange={e => handleColorChange(e.target.value)}
              maxLength={9}
            />
          ) : (
            <div className="flex ml-6 relative">
              {RGBA_VALUE.map(key => {
                const rgbaKey = key as keyof RGBAColorType;

                return (
                  <Input
                    key={key}
                    maxLength={3}
                    type={InputTypes.NUMBER}
                    customInputClass={`w-[32px] !pr-0 !pl-2 outline-none border focus:bg-lightest focus:border-primary border-gray-4 ${getRgbaInputBorderRadius(
                      key,
                    )} text-xs`}
                    value={Math.round(color[rgbaKey])}
                    onChange={e => handleRgbaInputChange(key, e.target.value)}
                    showErrorMessage={false}
                    error={
                      Number(color[rgbaKey]) > 255 || Number(color[rgbaKey] < 0)
                        ? 'Value should be between 0 - 255'
                        : ''
                    }
                  />
                );
              })}
            </div>
          )}
        </div>
        {hasInvalidRgbaValue && (
          <span className="text-red-1 text-xs absolute">
            Value should be between 0 - 255
          </span>
        )}
      </section>
      <div className="ml-6">
        <div className="text-sm font-medium ml-1.5 mt-4">From references</div>
        {PRESET_COLORS.map(item => (
          <button
            key={item}
            type="button"
            className="w-6 h-6 rounded-full m-1.5 border-2 drop-shadow"
            style={{ backgroundColor: item }}
            onClick={() => handleColorChange(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomColorPicker;
