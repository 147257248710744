import { FC, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  AngleDown,
  AngleUp,
  DocIcon,
  DocxIcon,
  EllipsisGrid,
  PdfIcon,
  PptIcon,
  PptxIcon,
} from '@assets/icons';
import { MoreOptions } from '@components/index';
import { KnowledgeExtractionStatusMap } from '@pages/home-page/constants';
import { AssetType, RawFileTypes } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';
import { toDateFormat } from '@utils/date';
import { getFileExt } from '@utils/file';

import DocumentPreviewModal from './DocumentPreview';

interface DraggableRowProps {
  item: DataItem | DataItem[];
  workspaceName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
  isGrouped?: boolean;
}

interface DocumentRowProps {
  item: DataItem;
  handleDelete: (id: number, assetType: AssetType) => void;
  showDragHandle?: boolean;
}

const iconMap: {
  [key in RawFileTypes]: React.FC<React.SVGProps<SVGSVGElement>>;
} = {
  [RawFileTypes.PDF]: PdfIcon,
  [RawFileTypes.DOCX]: DocxIcon,
  [RawFileTypes.DOC]: DocIcon,
  [RawFileTypes.PPT]: PptIcon,
  [RawFileTypes.PPTX]: PptxIcon,
};

const getKnowledgeStatusStyles = (status: number) => {
  switch (status) {
    case 1:
    case 2:
      return 'bg-yellow-5 text-yellow-3';
    case 3:
      return 'bg-red-2 text-red-3';
    case 4:
      return 'bg-green-2 text-green-3';
    default:
      return 'bg-gray-2 text-gray-3';
  }
};

const DocumentRow: FC<DocumentRowProps> = ({
  item,
  handleDelete,
  showDragHandle = true,
}) => {
  const FileIcon = iconMap[getFileExt(item.name) as RawFileTypes];
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  return (
    <>
      <div className="flex flex-row items-center border rounded  border-gray-2 py-2  bg-white my-2 px-2">
        <div className="w-[48%] flex flex-row gap-4 items-center">
          {showDragHandle && <EllipsisGrid className="h-4 w-4 flex-shrink-0" />}
          {FileIcon && <FileIcon className="h-5 w-5 flex-shrink-0" />}
          <div className="flex flex-col gap-1">
            <div className="text-sm">{item.name}</div>
            {item.knowledgeExtractionStatus && (
              <div
                className={`${getKnowledgeStatusStyles(item.knowledgeExtractionStatus)} rounded-xl px-2 py-1 w-fit text-xs`}
              >
                {
                  KnowledgeExtractionStatusMap[
                    item.knowledgeExtractionStatus as number
                  ]
                }
              </div>
            )}
          </div>
        </div>
        <div className="w-[20%] text-sm pl-4">{item.parentClientName}</div>
        <div className="w-[15%] text-sm pl-5">{item.createdBy}</div>
        <div className="w-[20%] text-sm pl-10">
          {toDateFormat(item.createdAt) || '-'}
        </div>
        <div
          className="flex justify-end"
          onPointerDown={e => e.stopPropagation()}
        >
          <MoreOptions
            onClickDelete={() => handleDelete(item.id, item.assetType)}
            isEditEnabled={false}
            {...(item.imageUrls?.length && {
              onClickPreview: () => setOpenPreviewModal(true),
            })}
          />
        </div>
      </div>
      {openPreviewModal && (
        <DocumentPreviewModal
          fileName={item.name}
          isOpen={openPreviewModal}
          onClose={() => setOpenPreviewModal(false)}
          imageUrls={item?.imageUrls as string[]}
        />
      )}
    </>
  );
};

const DraggableDocumentRow: FC<DraggableRowProps> = ({
  item,
  handleDelete,
  isGrouped = false,
}) => {
  const items = Array.isArray(item) ? item : [item];
  const firstItem = items[0];

  const [isExpanded, setIsExpanded] = useState(true);
  const ExpandIcon = isExpanded ? AngleUp : AngleDown;

  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform,
    isDragging,
  } = useSortable({
    id: firstItem.id,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <tr
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      key={firstItem.id}
    >
      <td className="px-2 bg-white" colSpan={4}>
        {!isGrouped ? (
          <DocumentRow item={firstItem} handleDelete={handleDelete} />
        ) : (
          <>
            <div className="flex justify-between px-2 my-2">
              <div className="text-sm font-semibold">Global documents</div>
              {items?.length > 1 && (
                <div
                  className=" flex text-sm font-semibold border rounded-2xl px-2 py-1 cursor-pointer"
                  onPointerDown={e => {
                    e.stopPropagation();
                    setIsExpanded(prev => !prev);
                  }}
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                  <ExpandIcon className="ml-2" />
                </div>
              )}
            </div>
            <div
              className={`flex bg-lightest rounded mb-2 ${!isExpanded ? 'shadow-xl' : ''}`}
            >
              <div className="flex flex-col justify-center">
                <EllipsisGrid className="h-4 w-5 flex-shrink-0 pl-2" />
              </div>
              <div className="flex-1 px-2 py-1">
                {isExpanded ? (
                  items.map(nestedItem => (
                    <DocumentRow
                      key={nestedItem.id}
                      item={nestedItem}
                      showDragHandle={false}
                      handleDelete={handleDelete}
                    />
                  ))
                ) : (
                  <DocumentRow
                    item={firstItem}
                    showDragHandle={false}
                    handleDelete={handleDelete}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default DraggableDocumentRow;
