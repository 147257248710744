import { FC, useMemo } from 'react';

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useRecoilState } from 'recoil';

import { AssetType } from '@pages/home-page/enums';
import { useUpdateAssetsHierarchy } from '@pages/home-page/hooks/use-update-assets-hierarchy';
import { DataItem } from '@pages/home-page/types';
import { documentList } from '@store/atoms/document-list';

import DraggableDocumentRow from './DraggableDocumentRow';

interface DocumentItemsProps {
  dataItems: DataItem[];
  workspaceId: number;
  workspaceName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
}

// Helper function to group document items
const groupDocumentItems = (
  items: DataItem[],
  workspaceId: number,
): (DataItem | DataItem[])[] => {
  const groupedItems: (DataItem | DataItem[])[] = [];
  let tempGroup: DataItem[] = [];

  for (const item of items) {
    if (item.parentClientId === workspaceId) {
      if (tempGroup.length) {
        groupedItems.push(tempGroup);
        tempGroup = [];
      }
      groupedItems.push(item);
    } else {
      tempGroup.push(item);
    }
  }

  if (tempGroup.length) groupedItems.push(tempGroup);

  return groupedItems;
};

const flattenItems = (items: (DataItem | DataItem[])[]) =>
  items.flatMap(item => (Array.isArray(item) ? item : [item]));

const DocumentItems: FC<DocumentItemsProps> = ({
  dataItems,
  workspaceId,
  workspaceName,
  handleDelete,
}) => {
  const [documentItems, setDocumentItems] = useRecoilState(documentList);

  const { mutateAsync: updateAssetHierarchy } = useUpdateAssetsHierarchy();

  const combinedItems = useMemo(
    () => groupDocumentItems(documentItems, workspaceId),
    [documentItems, workspaceId],
  );

  // Get position of document item in the combined list
  const getDocumentPosition = (id: number, items: (DataItem | DataItem[])[]) =>
    items.findIndex(item =>
      Array.isArray(item) ? item[0].id === id : item.id === id,
    );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    // Find original and new positions
    const originalIndex = getDocumentPosition(
      active.id as number,
      combinedItems,
    );
    const newIndex = getDocumentPosition(over.id as number, combinedItems);

    if (originalIndex === -1 || newIndex === -1) return;

    // Move items and flatten for state and API update
    const reorderedItems = arrayMove(combinedItems, originalIndex, newIndex);
    const flattenedItems = flattenItems(reorderedItems);

    setDocumentItems(flattenedItems);

    const documentsHierarchy = reorderedItems.flat().map(item => item.id);
    const response = await updateAssetHierarchy({
      clientId: workspaceId,
      payload: { documentsHierarchy },
    });
    if (!response) setDocumentItems(flattenItems(combinedItems));
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      <SortableContext items={dataItems} strategy={verticalListSortingStrategy}>
        {combinedItems.map(item => (
          <DraggableDocumentRow
            key={Array.isArray(item) ? `group-${item[0].id}` : item.id}
            item={item}
            workspaceName={workspaceName}
            handleDelete={handleDelete}
            isGrouped={Array.isArray(item)}
          />
        ))}
        <DragOverlay>{null}</DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default DocumentItems;
