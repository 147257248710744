import { FC, Fragment, useMemo } from 'react';

import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';
import { hexToRgbaString } from '@utils/color';

import DraggableAttributeRow from './DraggableAttributeRow';

interface DraggableAttributeSectionProps {
  workspaceId: number;
  knowledgeCategory: string;
  attributeItems: DataItem[];
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  isDraggable?: boolean;
}

const groupAttributeItems = (
  items: DataItem[],
  workspaceId: number,
): (DataItem | DataItem[])[] => {
  const groupedItems: (DataItem | DataItem[])[] = [];
  let tempGroup: DataItem[] = [];

  for (const item of items) {
    if (item.parentClientId === workspaceId) {
      if (tempGroup.length) {
        groupedItems.push(tempGroup);
        tempGroup = [];
      }
      groupedItems.push(item);
    } else {
      tempGroup.push(item);
    }
  }

  if (tempGroup.length) groupedItems.push(tempGroup);

  return groupedItems;
};

const DraggableAttributeSection: FC<DraggableAttributeSectionProps> = ({
  workspaceId,
  knowledgeCategory,
  attributeItems,
  handleDelete,
  handleEdit,
  isDraggable = true,
}) => {
  const primaryColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--primary-color')
    .trim();
  const transparentColor = hexToRgbaString(primaryColor, 0);

  const combinedAttributeItems = useMemo(
    () => groupAttributeItems(attributeItems, workspaceId),
    [attributeItems, workspaceId],
  );

  return (
    <SortableContext
      items={attributeItems}
      strategy={verticalListSortingStrategy}
    >
      <Fragment key={knowledgeCategory}>
        <div
          className="my-4 ml-4 py-2 px-3 uppercase text-sm w-fit font-semibold tracking-widest"
          style={{
            borderWidth: '2px 2px 0px 2px',
            borderImageSource: `linear-gradient(270deg, ${transparentColor} -8.32%,  var(--primary-color) 56.68%, ${transparentColor} 121.88%)`,
            borderImageSlice: 1,
            borderRadius: '20px',
          }}
        >
          {knowledgeCategory}
        </div>
        {combinedAttributeItems?.map(item => (
          <DraggableAttributeRow
            key={Array.isArray(item) ? `group-${item[0].id}` : item.id}
            item={item}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            isDraggable={isDraggable}
            workspaceId={workspaceId}
            isGrouped={Array.isArray(item)}
          />
        ))}
      </Fragment>
    </SortableContext>
  );
};

export default DraggableAttributeSection;
