import { FC, useMemo, useRef, useState } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';

import { Cross, ZoomIn, ZoomOut } from '@assets/icons';
import { Modal } from '@components/index';

interface DocumentPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  imageUrls: string[];
}

const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({
  isOpen,
  onClose,
  fileName,
  imageUrls,
}) => {
  const [selectedPage, setSelectedPage] = useState(imageUrls[0]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const parentRef = useRef<HTMLDivElement>(null);

  const selectedPageIndex = useMemo(() => {
    return imageUrls.indexOf(selectedPage) + 1;
  }, [selectedPage, imageUrls]);

  const imagePreviewVirtualizer = useVirtualizer({
    count: imageUrls.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 260,
    overscan: 5,
  });

  const handleZoomIn = (e: React.PointerEvent<Element>) => {
    e.stopPropagation();
    setZoomLevel(prev => Math.min(prev + 0.1, 1.8));
  }; // Limit max zoom level

  const handleZoomOut = (e: React.PointerEvent<Element>) => {
    e.stopPropagation();
    setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
  }; // Limit min zoom level

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-col w-[85vw] h-[90vh] pointer-events-auto overflow-auto">
        <div className="flex flex-row p-4 border-b justify-between items-center">
          <div className="flex flex-col gap-1">
            <div className="text-md font-semibold">{fileName}</div>
            <div className="text-sm font-semibold">{`Page ${selectedPageIndex} of ${imageUrls.length}`}</div>
          </div>
          <div className="flex gap-4 items-center">
            <ZoomIn className="cursor-pointer" onPointerDown={handleZoomIn} />
            <ZoomOut className="cursor-pointer" onPointerDown={handleZoomOut} />
            <div className="text-gray-1">|</div>
            <Cross
              className="cursor-pointer"
              onPointerDown={(e: React.PointerEvent<Element>) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        <div className="flex flex-1">
          <div
            ref={parentRef}
            className="flex flex-col gap-2 border-r p-5 w-[18%] overflow-auto"
          >
            <div className="relative">
              {imagePreviewVirtualizer.getVirtualItems().map(virtualRow => {
                const imageUrl = imageUrls[virtualRow.index];
                const isSelected = imageUrl === selectedPage;
                const selectedFileClass = isSelected ? 'shadow-2xl' : '';
                const selectedPageNumberClass = isSelected ? 'bg-lighter' : '';

                return (
                  <div
                    key={virtualRow.index}
                    className="cursor-pointer absolute top-0 left-0 w-full"
                    style={{
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    onMouseDown={() => {
                      setSelectedPage(imageUrl);
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt={`Page ${virtualRow.index + 1}`}
                      className={`w-full h-[220px] border rounded-md ${selectedFileClass}`}
                    />
                    <div
                      className={`absolute left-1/2 transform -translate-x-1/2 text-xs font-bold p-1 ${selectedPageNumberClass}`}
                    >
                      {virtualRow.index + 1}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col w-full items-center overflow-auto my-2">
            {selectedPage && (
              <img
                src={selectedPage}
                alt="Selected document preview"
                className="w-[55%] h-[100%] border-l border-r shadow-lg overflow-auto"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: 'top',
                  transition: 'transform 0.3s ease',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentPreviewModal;
