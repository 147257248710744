import { FC } from 'react';

import { Document, EllipsisGrid, Locked, Unlocked } from '@assets/icons';
import { MoreOptions } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { useLockAction } from '@pages/home-page/hooks/use-lock-action';
import { DataItem } from '@pages/home-page/types';
import { toDateFormat } from '@utils/date';

interface AttributeRowProps {
  item: DataItem;
  workspaceId: number;
  isDraggable?: boolean;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
}

const AttributeRow: FC<AttributeRowProps> = ({
  item,
  isDraggable = true,
  workspaceId,
  handleDelete,
  handleEdit,
}) => {
  const { mutate: toggleLock } = useLockAction(item.isLocked as boolean);

  const isInherited = item.parentClientId !== workspaceId;
  const showMoreActions = !item.isLocked || !isInherited;

  const getLockIcon = () => {
    return item.isLocked ? (
      <Locked className={`${isInherited ? 'text-gray-14' : 'text-primary'}`} />
    ) : (
      <Unlocked
        className={`${isInherited ? 'text-gray-14' : 'text-primary'}`}
      />
    );
  };

  return (
    <div className="w-full flex flex-1 flex-row rounded border border-gray-2 p-2 items-center relative bg-white mb-2 min-h-20">
      <div className="w-[48%] flex flex-row gap-4 items-center">
        {isDraggable && <EllipsisGrid className="h-4 w-4 flex-shrink-0" />}
        <div className="text-sm pr-4">{item.details}</div>
      </div>
      <div className="w-[20%] text-sm">
        {item?.parentClientName}
        {item.sourceDocument?.id && (
          <div className="flex items-center text-[13px] text-blue-2 border-blue-3 bg-blue-4 rounded-xl p-2 mt-1 font-semibold w-fit max-w-[200px] overflow-hidden">
            <Document className="mr-2" />
            <span className="w-full truncate mt-0.5">
              {item.sourceDocument?.label}
            </span>
          </div>
        )}
      </div>
      <div className="w-[15%] text-sm pl-5">{item.createdBy}</div>
      <div className="w-[20%] text-sm pl-10">
        {toDateFormat(item.createdAt) || '-'}
      </div>
      {showMoreActions && (
        <div
          className="flex flex-1 justify-end"
          onPointerDown={e => e.stopPropagation()}
        >
          <MoreOptions
            enabled={item.isLocked}
            showLock={!isInherited}
            onToggle={() => toggleLock({ attributeId: item.id })}
            isEditEnabled={!isInherited}
            onClickEdit={() => handleEdit?.(item, AssetType.KNOWLEDGE)}
            onClickDelete={() => handleDelete(item.id, AssetType.KNOWLEDGE)}
          />
        </div>
      )}
      <span
        className={`absolute bottom-0 right-0 p-2 rounded-tl-lg rounded-br-md ${isInherited ? 'bg-gray-2' : 'bg-lighter'}`}
      >
        {getLockIcon()}
      </span>
    </div>
  );
};

export default AttributeRow;
