import { FC } from 'react';

import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';

import { FolderEmpty } from '@assets/icons';
import { MoreOptions, Spinner } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { DataItem, GroupedKnowledgeItems } from '@pages/home-page/types';
import { workspace } from '@store/atoms/workspace';
import { toDateFormat } from '@utils/date';

import DocumentItems from './document-items/DocumentItems';
import KnowledgeAttributeItems from './knowledge-attributes/KnowledgeAttributeItems';

interface AssetDataItemsProps {
  dataItems: DataItem[];
  isLoading?: boolean;
  assetType: string;
  imageClassName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  knowledgeDataItems?: GroupedKnowledgeItems;
  isFilteredKnowledgeDataItems?: boolean;
}

const AssetDataItems: FC<AssetDataItemsProps> = ({
  dataItems,
  isLoading,
  imageClassName,
  assetType,
  handleDelete,
  handleEdit,
  knowledgeDataItems,
  isFilteredKnowledgeDataItems,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  const renderDetailItem = (item: DataItem) => {
    if ([AssetType.LOGO, AssetType.PRODUCT].includes(item.assetType)) {
      return (
        <div className="ml-2">
          <img
            src={item?.url}
            alt={`${item.assetType}_${item.id}`}
            className={imageClassName}
          />
        </div>
      );
    }

    if (item.assetType === AssetType.COLORS) {
      return (
        <div className="flex items-center border border-gray-2 px-2 py-1 w-max rounded">
          <div
            className="w-6 h-6 rounded-full m-1.5 drop-shadow mr-2.5 border"
            style={{ background: item.details }}
          />
          {item.details}
        </div>
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (
    dataItems.length === 0 ||
    (isEmpty(knowledgeDataItems) &&
      dataItems[0]?.assetType === AssetType.KNOWLEDGE)
  ) {
    const formattedAssetType = assetType.slice(0, -1).toLowerCase();

    return (
      <tr>
        <td colSpan={4} className="text-center py-8">
          <div className="flex flex-col items-center justify-center gap-1">
            <FolderEmpty />
            <p className="text-sm text-dark-3 font-semibold">File Empty</p>
            <p className="text-sm text-dark-3">
              {`Seems like no ${formattedAssetType} has been added to the library`}
            </p>
          </div>
        </td>
      </tr>
    );
  }

  if (dataItems[0]?.assetType === AssetType.KNOWLEDGE)
    return (
      <KnowledgeAttributeItems
        knowledgeDataItems={knowledgeDataItems as GroupedKnowledgeItems}
        workspaceId={currentWorkspace.id}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        isFiltered={isFilteredKnowledgeDataItems}
      />
    );

  if (dataItems[0]?.assetType === AssetType.RAW_FILE)
    return (
      <DocumentItems
        dataItems={dataItems}
        workspaceId={currentWorkspace?.id}
        workspaceName={currentWorkspace.name}
        handleDelete={handleDelete}
      />
    );

  return dataItems.map(item => {
    return (
      <tr className="w-full" key={item.id}>
        <td className="pl-5 py-2">
          <div className="flex flex-row gap-4 items-center text-sm">
            <div className="w-[310px] break-words">{item?.name}</div>
            <div className="pl-5 hyphens-auto">{renderDetailItem(item)}</div>
          </div>
        </td>
        <td className="py-2">
          <div className="text-sm pl-4">{item?.parentClientName}</div>
        </td>
        <td className="text-sm py-2 pl-1">{item?.createdBy}</td>
        <td>
          <div className="flex flex-row justify-between pr-3">
            <div className="text-sm py-2">
              {toDateFormat(item?.createdAt) || '-'}
            </div>
            <MoreOptions
              onClickDelete={() => handleDelete(item.id, item.assetType)}
              onClickEdit={() => handleEdit?.(item, item.assetType)}
              isEditEnabled={
                item.assetType !== AssetType.FONT ||
                item.parentClientId === currentWorkspace.id
              }
            />
          </div>
        </td>
      </tr>
    );
  });
};
export default AssetDataItems;
