import { FC, useMemo, useState } from 'react';

import { Document } from '@assets/icons';
import { Modal } from '@components/index';
import { RetrievedDocument } from '@hooks/types';
import { PreviewType } from '@pages/home-page/enums';

interface ChunkPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentList: RetrievedDocument[];
}

const ChunkPreviewModal: FC<ChunkPreviewModalProps> = ({
  isOpen,
  onClose,
  documentList,
}) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState(
    documentList[0]?.id,
  );
  const [activeTab, setActiveTab] = useState(PreviewType.TEXT);

  const fileNames = documentList.map((document: RetrievedDocument) => ({
    id: document.id,
    file_name: document.file_name,
    image_url: document.image_url,
  }));

  const { fileName, pageNumber, pageContent, imageUrl } = useMemo(() => {
    const selectedDocument = documentList.find(
      document => document.id === selectedDocumentId,
    );

    return {
      fileName: selectedDocument?.file_name || '',
      pageNumber: selectedDocument?.page_number || null,
      pageContent: selectedDocument?.page_content || null,
      imageUrl: selectedDocument?.image_url || '',
    };
  }, [selectedDocumentId, documentList]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-row w-[60vw] h-[70vh]">
        <div className="flex flex-col border-r mb-2 w-[35%] overflow-auto">
          <div className="flex flex-row border-b pt-6 pb-[22px] items-center text-sm">
            <span className="flex px-4 items-center mt-1 gap-2">
              <Document className=" stroke-dark-2" />
              Source document
            </span>
          </div>
          <div className="px-2 my-2">
            {fileNames.map(fileData => {
              const selectedFileClassOverride =
                fileData.id === selectedDocumentId
                  ? 'bg-lighter rounded-md'
                  : '';

              return (
                <div
                  key={fileData.id}
                  className={`text-sm cursor-pointer py-3 px-2 ${selectedFileClassOverride}`}
                  onClick={() => setSelectedDocumentId(fileData.id)}
                >
                  {fileData.file_name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between border-b px-4 pt-6 pb-3 ">
            <div className="text-base font-semibold">{fileName}</div>
            <div className="text-sm border p-1 rounded mb-1 mr-10">{`Page no. ${pageNumber}`}</div>
          </div>
          <div className="flex items-center justify-center m-3">
            <div className="flex border border-gray-200 rounded-2xl overflow-hidden">
              <span
                onClick={() => setActiveTab(PreviewType.TEXT)}
                className={`py-1 !w-32 cursor-pointer text-center ${
                  activeTab === PreviewType.TEXT
                    ? 'bg-lighter border-lighter text-primary'
                    : 'bg-white'
                }`}
              >
                Text
              </span>
              <span
                onClick={() => setActiveTab(PreviewType.DOCUMENT)}
                className={`py-1 !w-32 cursor-pointer text-center ${
                  activeTab === PreviewType.DOCUMENT
                    ? 'bg-lighter border-lighter text-primary'
                    : 'bg-white'
                }`}
              >
                Document
              </span>
            </div>
          </div>
          <div className="p-4 text-sm overflow-auto">
            {activeTab === PreviewType.TEXT ? (
              pageContent?.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))
            ) : (
              <img src={imageUrl} alt="Document image" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChunkPreviewModal;
