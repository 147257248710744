import { Dispatch, FC, SetStateAction } from 'react';

import { isEmpty } from 'lodash';

import { Add, AngleDown, AngleForward } from '@assets/icons';
import { Button } from '@components/index';
import { Actions, AssetType } from '@pages/home-page/enums';
import {
  DataItem,
  GroupedKnowledgeItems,
  SelectedAsset,
} from '@pages/home-page/types';

import AttributeFilter, { FilterData } from './AttributeFilter';

interface CollapsibleAssetWrapperProps {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  header: string;
  assetType: AssetType;
  setSelectedAction: Dispatch<SetStateAction<SelectedAsset | null>>;
  knowledgeDataItems?: GroupedKnowledgeItems;
  setFilteredKnowledgeItems: Dispatch<
    SetStateAction<GroupedKnowledgeItems | null>
  >;
}

export const CollapsibleAssetWrapper: FC<CollapsibleAssetWrapperProps> = ({
  isCollapsed,
  setIsCollapsed,
  header,
  assetType,
  setSelectedAction,
  knowledgeDataItems,
  setFilteredKnowledgeItems,
}) => {
  const handleCollapse = () => {
    setIsCollapsed(prev => !prev);
  };

  const getUniqueSourceDocuments = (
    knowledgeDataItems?: GroupedKnowledgeItems,
  ): FilterData[] => {
    const uniqueSourceDocs = new Set<number>();
    const availabelSourceDocuments: FilterData[] = [];

    if (knowledgeDataItems) {
      for (const dataItems of Object.values(knowledgeDataItems)) {
        for (const item of dataItems) {
          const sourceDocument = item.sourceDocument;
          if (sourceDocument?.id && !uniqueSourceDocs.has(sourceDocument.id)) {
            uniqueSourceDocs.add(sourceDocument.id);
            availabelSourceDocuments.push({
              id: sourceDocument.id,
              label: sourceDocument.label,
            });
          }
        }
      }
    }

    return availabelSourceDocuments;
  };

  const convertObjectToArray = (obj: GroupedKnowledgeItems) =>
    Object.keys(obj).map(key => ({ id: key, label: key }));

  const filterByDocuments = (
    dataItems: DataItem[],
    selectedDocuments: FilterData[],
  ) =>
    selectedDocuments.length > 0
      ? dataItems.filter(dataItem =>
          selectedDocuments.some(
            doc => doc.id === dataItem?.sourceDocument?.id,
          ),
        )
      : dataItems;

  const applyFilters = ({
    category: selectedCategories,
    document: selectedDocuments,
  }: {
    category: FilterData[];
    document: FilterData[];
  }) => {
    if (selectedCategories.length === 0 && selectedDocuments.length === 0) {
      setFilteredKnowledgeItems(null);

      return;
    }
    const filteredItems = Object.keys(knowledgeDataItems || {}).reduce(
      (result, category) => {
        const isCategorySelected = selectedCategories.some(
          option => option.label === category,
        );
        const dataItems = knowledgeDataItems?.[category] || [];
        let matchingDataItems: DataItem[] = [];
        if (selectedCategories.length && selectedDocuments.length) {
          // Both category and document filters are present
          if (isCategorySelected) {
            matchingDataItems = filterByDocuments(dataItems, selectedDocuments);
          }
        } else if (selectedCategories.length && isCategorySelected) {
          // Only category filter is present
          matchingDataItems = dataItems;
        } else if (selectedDocuments.length) {
          // Only document filter is present
          matchingDataItems = filterByDocuments(dataItems, selectedDocuments);
        }

        if (matchingDataItems.length > 0) {
          result[category] = matchingDataItems;
        }

        return result;
      },
      {} as GroupedKnowledgeItems,
    );

    setFilteredKnowledgeItems(filteredItems);
  };

  const onClearFilter = () => setFilteredKnowledgeItems(null);

  return (
    <tr className="w-full relative">
      <td colSpan={4}>
        <div className="mb-0.5">
          <div className="w-full border-t flex justify-between px-4">
            <button
              className="flex space-x-2 h-14 items-center w-full"
              onClick={handleCollapse}
            >
              <div className="flex space-x-3 items-center">
                {isCollapsed ? (
                  <AngleForward className="w-4 h-4" />
                ) : (
                  <AngleDown className="w-4 h-4" />
                )}
                <div className="text-sm">{header}</div>
              </div>
            </button>
            <div className="flex items-center gap-2">
              {assetType === AssetType.KNOWLEDGE &&
                !isEmpty(knowledgeDataItems) && (
                  <AttributeFilter
                    onApplyFilter={applyFilters}
                    onClearFilter={onClearFilter}
                    documentList={getUniqueSourceDocuments(knowledgeDataItems)}
                    categoryOptions={convertObjectToArray(
                      knowledgeDataItems as GroupedKnowledgeItems,
                    )}
                  />
                )}
              <div className="flex items-center">
                <Button
                  label="Add new"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedAction({ action: Actions.ADD, assetType });
                  }}
                  IconLeftComponent={Add}
                  classOverride="border-gray-4 px-2 text-sm text-gray-3 h-8"
                />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
